<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>属性值管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">属性值名称：</div>
                <el-input v-model="search.samllAttrName" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">所属属性：</div>
                <el-input v-model="search.attributesName" placeholder="搜索"></el-input>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="smallAttributesClassInfoAdd" @click="goAdd">新增属性值</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" prop="samllAttrName" label="属性值名称"></el-table-column>
            <el-table-column align="center" prop="attributesName"  label="所属属性"></el-table-column>
            <el-table-column align="center" prop="createDate" label="创建时间"></el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="smallAttributesClassInfoUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="smallAttributesClassInfoDel" @click="del(scope.row.id)">删除</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <add-small-attributes-class-info :type="type" :data="upData" :visible.sync="dialogVisible" @getData="getData"/>

    <el-dialog :visible.sync="dialogImgVisible">
      <img width="100%" :src="dialogImgUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import AddSmallAttributesClassInfo from "./add";
export default {
  components: {AddSmallAttributesClassInfo},
  data() {
    return {
      search: {
        samllAttrName: "",
        attributesName: ""
      },
      type:"",
      tableList: [],
      upList: [],
      allAttributesClassInfoList: [],
      dialogVisible: false,
      dialogImgVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      upData: {
        samllAttrName: "",
        attributesId: null,
        sort: 0,
      },
      rules: {
        samllAttrName: [{required: true, message: "请输入小分类名称", trigger: "blur"}],
        attributesId: [{required: true, message: "请选择所属分类", trigger: "change"}]
      },
      smallAttributesClassInfoAdd: false,
      smallAttributesClassInfoDel: false,
      smallAttributesClassInfoUpd: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getSmallAttributesClassInfo(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));
      list.forEach((v) => {
        if (v == "smallAttributesClassInfo:add") {
          _this.smallAttributesClassInfoAdd = true;
        }
        if (v == "smallAttributesClassInfo:del") {
          _this.smallAttributesClassInfoDel = true;
        }
        if (v == "smallAttributesClassInfo:upd") {
          _this.smallAttributesClassInfoUpd = true;
        }
      })
    },
    changeAttributesClassInfo(e) {
      let _this = this;
      if (e && _this.allAttributesClassInfoList.length === 0) {
        _this.getAllAttributesClassInfoData();
      }
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        samllAttrName: "",
        attributesName: ""
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },

    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = "add";
      _this.upData = {
        samllAttrName: "",
        attributesId: null,
        sort: 0,
      }
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'details';
      _this.upData = JSON.parse(JSON.stringify(e));
    },
    edit(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'edit';
      _this.upData = JSON.parse(JSON.stringify(e));
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delSmallAttributesClassInfo({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },
    // 上传start
    handleRemove() {
      let _this = this;
      _this.typeIcon = [];
    },
    handlePictureCardPreview(file) {
      let _this = this;
      _this.dialogImageUrl = file.url;
      _this.dialogVisible = true;
    },
    onExceed() {
      let _this = this;
      _this.$message.error("图片最多上传2张");
    },
    removeImage(e) {
      let _this = this;
      if (e) {
        _this.upList.splice(e, 1);
      } else {
        _this.upData.typeIcon = "";
      }
    },
    onError() {
      let _this = this;
      _this.$message.error("图片大小不能超过2M");
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let _this = this;

      if (!isLt2M) {
        _this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    fnUploadRequest(e) {
      let _this = this;
      _this.utils.upFile(e.file).then(res => {
        _this.upList = [];
        _this.upList.push(res);
        _this.upData.typeIcon = res.url;
      });
    }
    // 上传end
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}
</style>
