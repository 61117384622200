<template>
  <el-dialog
      :title="upData.id ? '修改' : '新增'"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
  >
    <el-form v-if="upData && formDataReady" :model="upData" :rules="rules" ref="ruleForm">
      <el-form-item label="属性值名称：" prop="samllAttrName">
        <el-input v-model="upData.samllAttrName" placeholder="请输入属性值名称"></el-input>
      </el-form-item>
      <el-form-item label="所属属性：" prop="attributesId">
        <el-select v-model="upData.attributesId" placeholder="请选择所属属性">
          <el-option :label="item.attributesName" :value="item.id" v-for="(item, index) in allAttributesClassInfoList"
                     :key="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序：" prop="sort">
        <el-input type="number" v-model="upData.sort" placeholder="请输入排序"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="add">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "add-smallAttributesClassInfo",
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(val) {
        this.upData = val;
      }
    },
    visible: {
      immediate: true,
      handler(val) {
        this.dialogVisible = val;
        this.getAllAttributesClassInfoData();
      }
    }
  },
  data() {
    return {
      upList: [],
      allAttributesClassInfoList: [],
      dialogVisible: false,
      dialogImgVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      upData: {
        samllAttrName: "",
        attributesId: null,
        sort: 0,
      },
      rules: {
        samllAttrName: [{required: true, message: "请输入小分类名称", trigger: "blur"}],
        attributesId: [{required: true, message: "请选择所属分类", trigger: "change"}]
      },
    }
  },
  methods: {
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    // 获取所有一级属性
    getAllAttributesClassInfoData() {
      let _this = this;
      if (_this.allAttributesClassInfoList.length > 0) {
        return;
      }
      const requestData = {
        page: this.page,
        pageSize: this.pageSize
      }
      _this.$api.getAllAttributesClassInfo(requestData).then(res => {
        if (res.data.code == 200) {
          _this.allAttributesClassInfoList = res.data.data;
        }
      });
    },
    add() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (!_this.upData.id) {
            _this.$api.addSmallAttributesClassInfo(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.btnLoading = false;
                _this.$message.success("添加成功");
                _this.handleClose();
              }
            });
          } else {
            _this.$api.editSmallAttributesClassInfo(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.btnLoading = false;
                _this.$message.success("修改成功");
                _this.handleClose();
              }
            });
          }
        } else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        samllAttrName: "",
        attributesId: null,
        sort: 0,
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
      _this.$emit('getData');
      _this.$emit('update:visible', false);
    },
  }
}
</script>

<style scoped>

</style>
